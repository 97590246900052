
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Billing page components
import Merchant from "layouts/merchant/allMerchants/merchant";
import PropTypes from "prop-types";
function MerchantInformation({ allMerchants, getAllMerchantsfunc }) {
    return (
        <Card id="delete-account">
            <MDBox pt={3} px={2}>
                <MDTypography variant="h6" fontWeight="medium">
                    Merchants Information
                </MDTypography>
            </MDBox>
            <MDBox pt={1} pb={2} px={2}>
                <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    {
                        allMerchants && allMerchants.map((item, index) => (
                            <Merchant
                                key={index}
                                id={item?.id}
                                first_name={item?.first_name || ''}
                                last_name={item?.last_name || ''}
                                business={item.business_name || ''}
                                email={item?.email_id || ''}
                                balance={item?.balance.toFixed(0)}
                                payoutBalance={item.payoutBalance.toFixed(0)}
                                premium={item.premium}
                                gateway={item.gateway || ''}
                                payoutGateway={item.payoutGateway || ''}
                                platformFee={item.platformFee || 0}
                                isBanned={item?.isBanned}
                                isActive={item?.payoutsActive}
                                func={getAllMerchantsfunc}
                            />
                        ))
                    }
                </MDBox>
            </MDBox>
        </Card>
    );
}
MerchantInformation.propTypes = {
    allMerchants: PropTypes.array,
    getAllMerchantsfunc: PropTypes.func

};
export default MerchantInformation;
